<template>
  <div class="pt-4" :class="{ 'bg-white': isMobile }">
    <v-card elevation="0" class="pa-5 pt-0 mb-5 overflow-hidden" :loading="loading">
      <v-form v-model="valid" class="mt-5">
        <v-row class="mx-0">
          <v-col
            cols="12"
            :md="$store.state.auth.currentUser.role === 'account_admin' ? 'auto' : 3"
            class="px-0 pb-3"
          >
            <label class="fz-14 mb-1 d-block">Type</label>
            <v-select
              v-model="form.isShared"
              :items="typeItems"
              :loading="loading"
              solo
              outlined
              dense
              menu-props="offsetY"
              item-value="value"
              item-text="name"
              hide-details
              label="Type"
              class="pa-0"
              style="width: 150px"
            >
              <template v-slot:append>
                <v-icon size="15px">unfold_more</v-icon>
              </template>
            </v-select>
          </v-col>

          <v-col
            cols="12"
            :md="$store.state.auth.currentUser.role === 'account_admin' ? 5 : 9"
            class="px-0 pl-md-3 pb-3"
          >
            <label class="fz-14 mb-1 d-block">Title</label>
            <v-text-field
              :maxlength="16"
              solo
              outlined
              type="text"
              v-model="form.title"
              dense
              hide-details
              @keypress="onlyAlphanumeric"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row v-if="!loading && !form.isShared">
          <v-col
            cols="12"
            md="12"
            class="pb-5 pt-0"
          >
            <v-divider class="hl-724"></v-divider>
          </v-col>

          <v-col
            cols="12"
            md="12"
            class="pt-0"
            style="position:relative"
          >
            <div
              :class="isMobile ? 'font-weight-medium text-capitalize' : 'font-weight-semi-bold fz-12 line-h-16 text-uppercase'"
              class="mb-1"
            >Mailbox access</div>

            <SearchDropdown
              is-selector
              :mailboxUuid="$route.params.uuid"
              @updateUsers="updateSearchUsers"
              @addNewUser="addUserSearch"
              @removeUser="removeSearchUser"
            />
          </v-col>

          <v-col
            cols="12"
            md="12"
            class="py-0"
            v-if="form.users.length"
          >
            <v-list class="py-0">
              <template v-for="(user, userIndex) in form.users">
                <v-divider :key="userIndex"></v-divider>
                <v-list-item :key="userIndex + 'msg'" class="px-0">
                  <v-list-item-avatar>
                    <v-img
                      :alt="`${user.firstName} avatar`"
                      :src="user.avatar"
                    ></v-img>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title
                      class="fz-14 font-weight-medium"
                      style="color: #1D2428;"
                    >
                      {{ fullName(user.firstName, user.lastName) }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>
          </v-col>
          <v-col v-else style="color:red">Need at least 1 assigned user</v-col>
        </v-row>
      </v-form>
    </v-card>
  </div>
</template>

<script>
import SearchDropdown from '@/components/SearchDropdown'
import { mapActions } from 'vuex'

export default {
  name: 'CreateMailbox',
  components: { SearchDropdown },
  props: {
    isDialog: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    loading: false,
    isFetching: false,
    oldName: '',
    typeItems: [
      {
        value: false,
        name: 'Private'
      },

      {
        value: true,
        name: 'Public'
      }
    ],
    valid: false,
    form: {
      title: '',
      isShared: null,
      users: []
    }
  }),
  created () {
    if (this.$route.params.uuid) {
      this.loadMailbox()
    } else {
      this.form.isShared = false
    }
  },
  computed: {
    changed () {
      return this.oldName !== this.form.title
    },
    isMobile () {
      return this.$vuetify.breakpoint.smAndDown
    },
    isDisable () {
      return this.isFetching || !this.form.title.trim() || (!this.form.isShared && !this.form.users.length)
    }
  },
  watch: {
    async 'form.isShared' (isShared) {
      if (!this.$route.params.uuid || isShared) return
      await this.editMailbox({
        uuid: this.$route.params.uuid,
        payload: { isShared }
      })
    },
    changed (val) {
      this.$emit('changed', val)
    },
    isDisable (val) {
      this.$emit('isDisable', val)
    }
  },
  methods: {
    ...mapActions([
      'createMailbox',
      'getMailbox',
      'editMailbox',
      'addUserToMailbox',
      'deleteUserFromMailbox'
    ]),
    async loadMailbox () {
      try {
        this.loading = true
        const { data } = await this.getMailbox(this.$route.params.uuid)

        this.oldName = this.form.title = data.mailbox.name
        this.form.isShared = data.mailbox.isShared
      } catch (err) {
        throw new Error(err)
      } finally {
        this.loading = false
      }
    },
    updateSearchUsers (users) {
      this.form.users = users
    },
    async removeSearchUser (uuid) {
      try {
        this.form.users = this.form.users.filter(e => e.uuid !== uuid)

        if (this.$route.name !== 'EditMailbox' || !uuid) return

        const payload = {
          mailboxUuid: this.$route.params.uuid,
          userUuid: uuid
        }
        const { data } = await this.deleteUserFromMailbox(payload)
        if (!data) return

        this.$snotify.notifications = []
        this.$snotify.success('User removed successfully')
      } catch (err) {
        this.$snotify.notifications = []
        this.$snotify.error(err.message)
        throw new Error(err)
      }
    },
    async addUserSearch (item) {
      try {
        if (this.form.users.findIndex(e => e.uuid === item.uuid) === -1) {
          this.form.users.push(item)
        }
        if (this.$route.name !== 'EditMailbox' || !item || !item.uuid) return

        const payload = {
          mailboxUuid: this.$route.params.uuid,
          userUuid: item.uuid
        }
        const { data } = await this.addUserToMailbox(payload)

        this.$snotify.notifications = []
        this.$snotify.success('User added successfully')
      } catch (err) {
        this.$snotify.notifications = []
        this.$snotify.error(err.message)
        throw new Error(err)
      }
    },
    fullName (firstname, lastname) {
      return (firstname || '') + ' ' + (lastname || '')
    },
    async create () {
      if (this.isFetching) return
      this.isFetching = true
      if (this.$route.name === 'EditMailbox') {
        this.edit()
        return
      }
      try {
        const payload = {
          mailboxName: this.form.title,
          isShared: this.form.isShared
        }
        if (!this.form.isShared) payload.usersWithAccess = this.form.users.map(item => item.uuid)

        const { data } = await this.createMailbox(payload)
        if (!data) return
        this.$snotify.notifications = []
        this.$snotify.success('Successful')
        if (this.isDialog) {
          this.$emit('close')
        } else {
          setTimeout(e => {
            this.$router.push({ name: 'Mailboxes' })
          }, 3000)
        }
      } catch (err) {
        let message = Array.isArray(err.message) ? err.message[0] : []
        if (message === 'usersWithAccess must contain no more than 10 elements') {
          message = 'Users with access must contain not more than 10 elements'
        } else if (message.includes('mailboxName must be shorter')) {
          message = 'Mailbox name be shorter than or equal to 30 characters'
        }
        this.$snotify.error(message)
        throw new Error(err)
      } finally {
        setTimeout(e => {
          this.isFetching = false
        }, 3200)
      }
    },
    async edit () {
      try {
        const payload = {
          isShared: this.form.isShared
        }
        if (this.changed) payload.mailboxName = this.form.title
        await this.editMailbox({
          uuid: this.$route.params.uuid,
          payload
        })
        this.$snotify.notifications = []
        this.$snotify.success('Successful')
        if (this.isDialog) {
          this.$emit('close')
        } else {
          setTimeout(e => {
            this.$router.push({ name: 'Mailboxes' })
          }, 3000)
        }
      } catch (err) {
        let message = Array.isArray(err.message) ? err.message[0] : []
        if (message === 'usersWithAccess must contain no more than 10 elements') {
          message = 'Users with access must contain not more than 10 elements'
        } else if (message.includes('mailboxName must be shorter')) {
          message = 'Mailbox name be shorter than or equal to 30 characters'
        }
        this.$snotify.error(message)
        throw new Error(err)
      } finally {
        setTimeout(e => {
          this.isFetching = false
        }, 3200)
      }
    }
  }
}
</script>
