<template>
  <div :class="{ 'mobile-recovery': !isDesktop }" class="py-0 d-flex justify-center fill-height">
    <v-card
      class="ma-auto pa-md-10 pa-0 h-100-mobile w-100"
      max-width="560px"
      width="100%"
    >
      <div class="d-flex d-md-none align-center justify-center w-100 pa-5" style="background-color: #00084B">
        <v-img
          position="left center"
          :src="require('@/assets/logo.svg')"
          height="31px"
          max-width="120px"
          contain
        ></v-img>
      </div>
      <v-card-text class="pa-0 pt-3 px-4 px-md-0">
        <router-link to="/login" class="back-link">
          <v-icon size="24px">navigate_before</v-icon>
          Sign In
        </router-link>
      </v-card-text>

      <v-card-title class="pa-0 px-md-0 px-4">
        <h2 class="font-weight-semi-bold fz-28 dark-text">Forgot password</h2>
      </v-card-title>

      <template>
        <div v-if="!show">
          <v-card-subtitle class="pa-0 px-4 px-md-0 pt-4 pb-3">
            Enter your Email and we will send you the code to reset your password
          </v-card-subtitle>

          <v-card-text class="pa-0 px-4 px-md-0 pb-4">
            <v-form ref="forgetForm" v-model="valid">
              <v-row class="no-gutters">
              <v-col
                v-if="!show"
                cols="12"
                md="12"
                class="pa-0 pb-2"
              >
                <label class="fz-14 mb-1 d-block dark-text">Email</label>
                <v-text-field
                  solo
                  outlined
                  type="email"
                  v-model="form.email"
                  :rules="emailRules"
                  dense
                  required
                ></v-text-field>
              </v-col>
              </v-row>
              <v-row class="position-absolute-mobile align-baseline mt-0"
                     :class="!isDesktop ? 'no-gutters' : ''">
              <v-col
                cols="12"
                md="12"
                class="d-flex flex-row justify-end py-0 px-4 px-md-3"
              >
                <v-btn
                  :disabled="!form.email"
                  :class="!isDesktop ? 'w-100' : ''"
                  class="violet-button py-3 px-6"
                  height="44px"
                  @click="resetUserPassword"
                >Send the code
                </v-btn>
              </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </div>
        <div v-else>
          <v-card-text class="pa-0 pt-4">
            <v-form ref="newForm" v-model="isFormValid" class="row no-gutters">
              <v-col
                cols="12"
                class="px-4 px-lg-0 px-md-0 pt-0"
              >
                <label class="fz-14 mb-1 d-block">New password</label>
                <v-text-field
                  v-model="form.password"
                  :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showPass ? 'text' : 'password'"
                  :rules="passwordRules"
                  autocomplete="new-password"
                  solo
                  outlined
                  label="New password"
                  dense
                  @click:append="showPass = !showPass"
                ></v-text-field>
              </v-col>

              <v-col
                cols="12"
                class="px-4 px-lg-0 px-md-0 pt-0"
              >
                <label class="fz-14 mb-1 d-block">Confirm password</label>
                <v-text-field
                  v-model="form.rePassword"
                  :append-icon="showRePass ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="showRePass ? 'text' : 'password'"
                  solo
                  outlined
                  label="Confirm"
                  :rules="[(form.password === form.rePassword) || 'Passwords should be the same']"
                  dense
                  @click:append="showRePass = !showRePass"
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="12"
                class="d-flex flex-row justify-end pa-4 pa-lg-0 pa-md-0 mt-7"
              >
                <v-btn
                  :disabled="!isFormValid"
                  :class="!isDesktop ? 'w-100' : ''"
                  class="violet-button py-3 px-6"
                  height="44px"
                  @click="saveChangePassword"
                >Reset
                </v-btn>
              </v-col>
            </v-form>
          </v-card-text>
        </div>
      </template>
    </v-card>

    <v-alert
      v-if="resetSuccess"
      close-text="Close Alert"
      dismissible
      class="success-fixed-alert"
      transition="scroll-y-transition"
    >
      <template v-slot:prepend>
        <v-sheet
          width="20px"
          height="20px"
          class="rounded-circle d-flex justify-center mt-1 ml-1"
          color="#fff"
          style="border: 2px solid #50B83C; box-shadow: rgb(187 229 179) 0 0 0 6px"
        >
          <v-icon size="14px" color="#50B83C" class="font-weight-semi-bold">check</v-icon>
        </v-sheet>
      </template>

      <div class="d-flex flex-column ml-5 mt-1">
        <span class="fz-16 font-weight-semi-bold dark-text">Your password is successfully reset.</span>
        <v-btn
          to="/login"
          class="transparent-button py-2 px-4 mt-3"
          width="129px"
        >
          Back to sign in
        </v-btn>
      </div>

      <template v-slot:close>
        <v-icon @click="closeAlert()" class="align-self-start pointer">close</v-icon>
      </template>
    </v-alert>
    <vue-snotify/>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  name: 'ForgotPassword',
  data: () => ({
    isFormValid: false,
    resetSuccess: false,
    form: {
      email: '',
      password: '',
      rePassword: ''
    },
    valid: false,
    showPass: false,
    showRePass: false,
    emailRules: [
      v => !!v || 'E-mail is required',
      v => /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/.test(v) || 'E-mail must be valid'
    ]
  }),
  computed: {
    passwordRules () {
      return [
        v => !!v || 'Password is required',
        v => v.length >= 6 || 'Password must be at least 6 characters',
        v => v.length <= 20 || 'Password must be less than 20 characters',
        v => /(?=.*[!@#$%^&*])/.test(v) || 'Password must contain at least one special character',
        v => /(?=.*[0-9])/.test(v) || 'Password must contain at least one numeric character',
        v => /(?=.*?[A-Z])/.test(v) || 'Password must contain at least one uppercase alphabetical character',
        v => /(?=.*?[a-z])/.test(v) || 'Password must contain at least one lowercase alphabetical character',
        v => v === this.form.password || 'Passwords does not match'
      ]
    },
    show () {
      return this.$route.params.token && this.$route.params.uuid
    },
    isDesktop () {
      return this.$vuetify.breakpoint.mdAndUp
    }
  },
  methods: {
    ...mapActions(['resetPassword', 'changePassword']),
    async resetUserPassword () {
      if (!this.$refs.forgetForm.validate()) return
      this.$snotify.notifications = []
      try {
        await this.resetPassword({ form: this.form })
      } catch (err) {
        console.log(err)
      } finally {
        this.$snotify.success('You will receive an email if your email exists.')
        setTimeout(() => {
          this.$router.push({ name: 'Login' })
        }, 4000)
      }
    },
    async saveChangePassword () {
      if (!this.$refs.newForm.validate()) return
      try {
        const body = {
          token: this.$route.params.token,
          uuid: this.$route.params.uuid,
          newPassword: this.form.password,
          rePassword: this.form.rePassword
        }
        this.$snotify.notifications = []
        const { data } = await this.changePassword(body)
        if (!data) return
        const options = {
          timeout: 3000,
          buttons: [
            {
              text: 'Back to sign in',
              action: (toast) => {
                this.$router.push({ name: 'Login' })
                this.$snotify.remove(toast.id)
              }
            }
          ]
        }
        this.$snotify.success('Your password is successfully reset.', options)
        setTimeout(() => {
          this.$router.push({ name: 'Login' })
        }, 3000)
      } catch (e) {
        this.$snotify.error(e.message)
      }
    },
    closeAlert () {
      this.resetSuccess = false
    }
  }
}
</script>
<style lang="scss" scoped>
  .w-100 {
    width: 100%;
  }
  .mobile-recovery ::v-deep .v-text-field__details .v-messages__message {
    font-size: 14px !important;
  }
</style>
